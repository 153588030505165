<template>
  <b-overlay
    :show="applyOverlayD"
    :class="applyOverlayD ? 'p-2 mb-1' : 'mb-1'"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <b-row v-if="!applyOverlayD">
      <!-- Obra -->
      <b-col md="12">
        <b-row>
          <b-col md="6">
            <h5 class="mt-1">
              <strong>{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.literaryOrArtisticWorkData') }}</strong>
            </h5>
          </b-col>
          <b-col md="6">
            <span
              v-if="nWorks > 1 && checkPermission(['DELETE_LITERARY_OR_ARTISTIC_WORK_REGISTRATION', 'ALLOW_ALL'])"
              class="float-right mt-1 ml-1"
            >
              {{ $t( 'buttons.delete') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="danger"
                @click="$emit('delete-work', actions.workId)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </span>
            <span
              v-if="checkPermission(['EDIT_LITERARY_OR_ARTISTIC_WORK_REGISTRATION', 'ALLOW_ALL'])"
              class="float-right mt-1"
            >
              {{ $t( 'buttons.edit') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="secondary"
                @click="$emit('edit-work', actions.workId)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </b-col>
        </b-row>
        <hr style="margin-top: 8px;">
      </b-col>
      <b-col md="12">
        <b-list-group>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.typesOfLiteraryOrArtisticWork') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ workInfoD.type_of_literary_or_artistic_work.labelables[0].label }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.dateOfRequest') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ workInfoD.date_of_request == null ? $t('generic.undefinedDate') : workInfoD.date_of_request | formatDate() }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.dateOfConcession') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ workInfoD.date_of_concession == null ? $t('generic.undefinedDate') : workInfoD.date_of_concession | formatDate() }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.titleOfLiteraryOrArtisticWork') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ workInfoD.title_of_literary_or_artistic_work }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.holderName') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ workInfoD.holder_name }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.creatorAndOrDesigner') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ workInfoD.creator_and_or_designer }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.applicationFile') }}</strong>
            </b-col>
            <b-col md="6">
              <span>. . .</span>
              <b-media
                class="float-right"
                no-body
              >
                <span
                  class="i-c-pointer cursor-pointer float-right"
                  @click="showFileD = !showFileD"
                >
                  {{ $t('generic.see') }}
                  <feather-icon
                    v-if="!showFileD"
                    icon="FileIcon"
                    size="17"
                    style="padding-bottom: 2px"
                  />
                </span>
                <div
                  v-if="showFileD == true"
                  class="ml-2"
                  style="display: flex;"
                >
                  <div
                    v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFileD == true"
                    class="float-right"
                    style="display: flex;"
                  >
                    <div v-if="isObject(workInfoD.application_media_file) && workInfoD.application_media_file.mime_type != 'application/pdf'">
                      <image-viewer
                        class="cursor-pointer mr-1"
                        :document-id="workInfoD.application_file_hash"
                        :url-thumb="workInfoD.application_media_file.url_thumb"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-else>
                      <ViewerDocumentModal
                        class="cursor-pointer mr-1"
                        :title="workInfoD.application_media_file ? workInfoD.application_media_file.title : ''"
                        :document-id="workInfoD.application_file_hash"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-if="workInfoD.application_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                      <span
                        class="float-right cursor-pointer"
                        @click="handleDownloadFile(workInfoD.application_file_hash, workInfoD.application_media_file.title)"
                      >
                        <i
                          class="icon-0-icons-dark-download btn-cursor"
                          style="font-size: 20px;"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </b-media>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.contractFile') }}</strong>
            </b-col>
            <b-col md="6">
              <span>. . .</span>
              <b-media
                class="float-right"
                no-body
              >
                <span
                  class="i-c-pointer cursor-pointer float-right"
                  @click="showFileTwoD = !showFileTwoD"
                >
                  {{ $t('generic.see') }}
                  <feather-icon
                    v-if="!showFileTwoD"
                    icon="FileIcon"
                    size="17"
                    style="padding-bottom: 2px"
                  />
                </span>
                <div
                  v-if="showFileTwoD == true"
                  class="ml-2"
                  style="display: flex;"
                >
                  <div
                    v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFileTwoD == true"
                    class="float-right"
                    style="display: flex;"
                  >
                    <div v-if="isObject(workInfoD.contract_media_file) && workInfoD.contract_media_file.mime_type != 'application/pdf'">
                      <image-viewer
                        class="cursor-pointer mr-1"
                        :document-id="workInfoD.contract_file_hash"
                        :url-thumb="workInfoD.contract_media_file.url_thumb"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-else>
                      <ViewerDocumentModal
                        class="cursor-pointer mr-1"
                        :title="workInfoD.contract_media_file ? workInfoD.contract_media_file.title : ''"
                        :document-id="workInfoD.contract_file_hash"
                        :height="'30'"
                        :width="'30'"
                        :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                        :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                      />
                    </div>
                    <div v-if="workInfoD.contract_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                      <span
                        class="float-right cursor-pointer"
                        @click="handleDownloadFile(workInfoD.contract_file_hash, workInfoD.contract_media_file.title)"
                      >
                        <i
                          class="icon-0-icons-dark-download btn-cursor"
                          style="font-size: 20px;"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </b-media>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-col>
      <login-modal
        :permission="validPermissionD"
        @change="toAccess"
      />
    </b-row>
    <b-row v-else>
      <b-col
        md="12"
        class="i-height-div"
      />
    </b-row>
  </b-overlay>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapActions } from 'vuex'
import { isObject, downloadFile } from '@core/utils/utils'
import { BListGroup, BListGroupItem, BButton } from 'bootstrap-vue'
import literaryOAWService from '@/services/literaryOrArtisticWorkService'
import ImageViewer from '@/views/member/ImageViewer.vue'
import ViewerDocumentModal from '@/views/member/ViewerDocumentModal.vue'
import LoginModal from '@/views/member/LoginModal.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
    ImageViewer,
    ViewerDocumentModal,
    LoginModal,
  },

  props: {
    actions: {
      type: Object,
      default: () => {
      },
    },
    nWorks: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      showFileD: false,
      showFileTwoD: false,
      validPermissionD: null,
      applyOverlayD: false,
      workInfoD: {},
    }
  },

  created() {
    this.fetchWorkInfo()
  },

  methods: {
    downloadFile,
    isObject,
    ...mapActions({ mediaFileShow: 'mediaFile/show' }),

    fetchWorkInfo() {
      this.applyOverlayD = true

      literaryOAWService.getLiteraryOrArtisticWorkInfo(this.actions.workId).then(({ data }) => {
        this.workInfoD = data.data
        this.applyOverlayD = false
      }).catch(error => {
        this.applyOverlayD = false
        this.responseCatch(error)
      })
    },

    async toAccess(permissionData) {

    },

    async handleDownloadFile(fileHash, title) {
      this.isLoading = true
      await this.mediaFileShow(fileHash).then(response => {
        const { data: dataDocument } = response
        this.downloadFile(dataDocument, title)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
.i-c-pointer:hover {
  color: #6abeff;
}
</style>
