<template>
  <b-row>
    <b-col cols="12">
      <h5>
        <strong>{{ $t('clubs.intellectualProperty.literaryOrArtisticWorkRegistration') }}</strong>
      </h5>
      <span class="i-text-request-info">
        {{ $t('corporate.create.textRequest') }}
      </span>
      <hr>
    </b-col>
    <!-- form-->
    <b-col md="12">
      <b-overlay
        :show="applyOverlay"
        :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
        opacity="1"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-row>
          <b-col
            v-if="!applyOverlay"
            md="12"
          >
            <validation-observer ref="workRules">
              <b-form @submit.prevent="saveRegisterForm">
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="loaw-id">
                      {{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.typesOfLiteraryOrArtisticWork') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="typeOfLiteraryOrArtisticWork"
                        rules="required"
                      >
                        <v-select
                          id="loaw-id"
                          v-model="registerForm.typeOfLiteraryOrArtisticWorkId"
                          :state="errors.length > 0 ? false : null"
                          :reduce="option => option.id"
                          label="name"
                          :options="literaryWorkTypes"
                          :placeholder="$t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.placeholder.selectTypeOfLiteraryOrArtisticWork')"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <label
                      class="i-font-size-date"
                      for="loaw-date-of-request"
                    >
                      {{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.dateOfRequest') }}
                    </label>
                    <b-form-datepicker
                      id="loaw-date-of-request"
                      v-model="registerForm.dateOfRequest"
                      hide-header
                      :locale="$i18n.locale"
                      :placeholder="$t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.placeholder.selectDateOfRequest')"
                      local="es"
                      :max="todayLoaw"
                    />
                  </b-col>
                  <b-col md="6">
                    <label
                      class="i-font-size-date"
                      for="loaw-date-of-concession"
                    >
                      {{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.dateOfConcession') }}
                    </label>
                    <b-form-datepicker
                      id="loaw-date-of-concession"
                      v-model="registerForm.dateOfConcession"
                      hide-header
                      :locale="$i18n.locale"
                      :placeholder="$t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.placeholder.selectDateOfConcession')"
                      local="es"
                      :disabled="registerForm.dateOfRequest == null || registerForm.dateOfRequest == ''"
                      :min="minDateCn"
                    />
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="loaw-title">
                      {{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.titleOfLiteraryOrArtisticWork') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="titleOfLiteraryOrArtisticWork"
                        rules="required|min:1|max:255|clubNameRegex"
                      >
                        <b-form-input
                          id="loaw-title"
                          v-model="registerForm.titleOfLiteraryOrArtisticWork"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.placeholder.enterTitleOfLiteraryOrArtisticWork')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="loaw-holder-name">
                      {{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.holderName') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="holderName"
                        rules="required|min:1|max:255|nameRegex"
                      >
                        <b-form-input
                          id="loaw-holder-name"
                          v-model="registerForm.holderName"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.placeholder.enterHolderName')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="loaw-creator">
                      {{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.creatorAndOrDesigner') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="creatorAndOrDesigner"
                        rules="required|min:1|max:255|nameRegex"
                      >
                        <b-form-input
                          id="loaw-creator"
                          v-model="registerForm.creatorAndOrDesigner"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.placeholder.enterCreatorAndOrDesigner')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="actions.name == 'edit'"
                    md="6"
                  >
                    <upload-file
                      :title="$t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.applicationFile')"
                      folder="clubs"
                      :club-id="registerForm.clubId"
                      :file-title="registerForm.applicationFile ? registerForm.applicationFile.title : null"
                      @uploaded-file="assignFileDinamic($event, 1)"
                    />
                  </b-col>
                  <b-col
                    v-else
                    md="6"
                  >
                    <b-form-group>
                      {{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.applicationFile') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="applicationFileL"
                        rules="required"
                      >
                        <b-form-file
                          ref="file-application-input"
                          v-model="registerForm.applicationFile"
                          :browse-text="$t('buttons.explore')"
                          accept="image/jpeg, image/png, image/jpg, application/pdf"
                          :placeholder="$t('generic.uploadDocument')"
                          :state="errors.length > 0 ? false:null"
                          no-drop
                          @change="fileValidation($event,'file-application-input')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="actions.name == 'edit'"
                    md="6"
                  >
                    <upload-file
                      :title="$t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.contractFile')"
                      folder="clubs"
                      :club-id="registerForm.clubId"
                      :file-title="registerForm.contractFile ? registerForm.contractFile.title : null"
                      @uploaded-file="assignFileDinamic($event, 2)"
                    />
                  </b-col>
                  <b-col
                    v-else
                    md="6"
                  >
                    <b-form-group>
                      {{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.contractFile') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="contractFile"
                        rules="required"
                      >
                        <b-form-file
                          ref="file-contract-input"
                          v-model="registerForm.contractFile"
                          :browse-text="$t('buttons.explore')"
                          accept="image/jpeg, image/png, image/jpg, application/pdf"
                          :placeholder="$t('generic.uploadDocument')"
                          :state="errors.length > 0 ? false:null"
                          no-drop
                          @change="fileValidation($event,'file-contract-input')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <hr>
                    <b-button
                      class="float-right ml-1"
                      variant="primary"
                      pill
                      :style="colorPrimaryBtn"
                      type="submit"
                    >
                      {{ $t( 'buttons.save') }}
                    </b-button>
                    <b-button
                      class="float-right"
                      variant="secondary"
                      pill
                      @click="$emit('change-type-view')"
                    >
                      {{ $t('buttons.cancel') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <!--/ form -->
          </b-col>
          <b-col
            v-else
            cols="12"
            class="i-height-div"
          />
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min, max, nameRegex, clubNameRegex } from '@validations'
import { BRow, BCol, BFormGroup, BForm, BFormInput, BFormDatepicker, BButton, VBTooltip } from 'bootstrap-vue'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import { literaryWorkTypes } from '@/services/catalogsService'
import { destroyMediaFile } from '@/services/mediaFileService'
import literaryOAWService from '@/services/literaryOrArtisticWorkService'
import UploadFile from '@/views/member/components/UploadFile.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    UploadFile,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BFormDatepicker,
    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      todayLoaw: new Date(),
      applyOverlay: false,
      applyDeleteFileApp: false,
      previousFileHashApp: null,
      applyDeleteFileC: false,
      previousFileHashC: null,
      literaryWorkTypeList: [],
      registerForm: {
        id: null,
        clubId: null,
        typeOfLiteraryOrArtisticWorkId: null,
        dateOfRequest: null,
        dateOfConcession: null,
        titleOfLiteraryOrArtisticWork: null,
        holderName: null,
        creatorAndOrDesigner: null,
        applicationFile: null,
        applicationFileId: null,
        contractFile: null,
        contractFileId: null,
        status: 1,
      },
      /* validation */
      required,
      min,
      max,
      nameRegex,
      clubNameRegex,
    }
  },

  computed: {
    ...mapGetters({ typesOLOAWList: 'typesOLOAWList', colorPrimaryBtn: 'colorPrimaryBtn' }),

    literaryWorkTypes() {
      const list = this.literaryWorkTypeList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },

    minDateCn() {
      return moment(this.registerForm.dateOfRequest).format()
    },
  },

  watch: {
    'registerForm.dateOfRequest': function (newValue, oldValue) {
      if (oldValue != null && (newValue != oldValue || newValue == null || newValue == '')) {
        this.registerForm.dateOfConcession = null
      }
    },

    '$i18n.locale': function () {
      this.fetchLiteraryWorkTypes()
    },
  },

  mounted() {
    this.assignArtisticWork()
  },

  methods: {
    validatorFileSize,
    validatorFileType,

    fetchLiteraryWorkTypes() {
      literaryWorkTypes().then(({ data }) => {
        this.literaryWorkTypeList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    assignArtisticWork() {
      this.fetchLiteraryWorkTypes()

      if (this.actions.name == 'edit') {
        this.applyOverlay = true

        literaryOAWService.getLiteraryOrArtisticWorkInfo(this.actions.workId).then(({ data }) => {
          const artisticWorkData = data.data
          this.registerForm.id = artisticWorkData.hash
          this.registerForm.clubId = artisticWorkData.club_hash
          this.registerForm.typeOfLiteraryOrArtisticWorkId = artisticWorkData.type_of_literary_or_artistic_work_hash
          this.registerForm.dateOfRequest = artisticWorkData.date_of_request
          this.registerForm.dateOfConcession = artisticWorkData.date_of_concession
          this.registerForm.titleOfLiteraryOrArtisticWork = artisticWorkData.title_of_literary_or_artistic_work
          this.registerForm.holderName = artisticWorkData.holder_name
          this.registerForm.creatorAndOrDesigner = artisticWorkData.creator_and_or_designer
          this.registerForm.applicationFile = artisticWorkData.application_media_file
          this.registerForm.applicationFileId = artisticWorkData.application_media_file.hash
          this.registerForm.contractFile = artisticWorkData.contract_media_file
          this.registerForm.contractFileId = artisticWorkData.contract_media_file.hash
          this.registerForm.status = artisticWorkData.status
          this.applyOverlay = false
        }).catch(error => {
          this.applyOverlay = false
          this.responseCatch(error)
        })
      }
    },

    saveRegisterForm() {
      this.$refs.workRules.validate().then(success => {
        if (success) {
          if (this.actions.name == 'edit') {
            const formData = {
              id: this.actions.workId,
              club_id: parseInt(this.actions.clubId, 10),
              type_of_literary_or_artistic_work_id: this.registerForm.typeOfLiteraryOrArtisticWorkId,
              date_of_request: this.registerForm.dateOfRequest,
              date_of_concession: this.registerForm.dateOfConcession,
              title_of_literary_or_artistic_work: this.registerForm.titleOfLiteraryOrArtisticWork,
              holder_name: this.registerForm.holderName,
              creator_and_or_designer: this.registerForm.creatorAndOrDesigner,
              application_file: this.registerForm.applicationFile,
              application_file_id: this.registerForm.applicationFileId,
              contract_file: this.registerForm.contractFile,
              contract_file_id: this.registerForm.contractFileId,
              status: this.registerForm.status ? 1 : 0,
            }

            literaryOAWService.updateLiteraryOrArtisticWork(formData).then(({ data }) => {
              this.responseSuccessUpdate(data.message)

              if (this.applyDeleteFileApp || this.applyDeleteFileC) {
                this.deleteFileX()
              } else {
                this.$emit('change-type-view')
              }
            }).catch(error => {
              this.responseCatch(error)
            })
          } else {
            const formData = new FormData()
            formData.append('club_id', parseInt(this.actions.clubId, 10))
            formData.append('type_of_literary_or_artistic_work_id', this.registerForm.typeOfLiteraryOrArtisticWorkId)
            formData.append('date_of_request', this.registerForm.dateOfRequest)
            formData.append('date_of_concession', this.registerForm.dateOfConcession)
            formData.append('title_of_literary_or_artistic_work', this.registerForm.titleOfLiteraryOrArtisticWork)
            formData.append('holder_name', this.registerForm.holderName)
            formData.append('creator_and_or_designer', this.registerForm.creatorAndOrDesigner)
            formData.append('application_file', this.registerForm.applicationFile)
            formData.append('contract_file', this.registerForm.contractFile)
            formData.append('status', this.registerForm.status)

            literaryOAWService.storeLiteraryOrArtisticWork(formData).then(({ data }) => {
              this.responseSuccessCreate(data.message)
              this.$emit('change-type-view')
            }).catch(error => {
              this.responseCatch(error)
            })
          }
        }
      })
    },

    assignFileDinamic(responseFile, opt) {
      if (opt == 1) {
        if (this.registerForm.applicationFileId == null) {
          this.registerForm.applicationFile = responseFile
          this.registerForm.applicationFileId = responseFile.hash
        } else {
          this.previousFileHashApp = this.registerForm.applicationFileId
          this.applyDeleteFileApp = true
          this.registerForm.applicationFile = responseFile
          this.registerForm.applicationFileId = responseFile.hash
        }
      } else {
        if (this.registerForm.contractFileId == null) {
          this.registerForm.contractFile = responseFile
          this.registerForm.contractFileId = responseFile.hash
        } else {
          this.previousFileHashC = this.registerForm.contractFileId
          this.applyDeleteFileC = true
          this.registerForm.contractFile = responseFile
          this.registerForm.contractFileId = responseFile.hash
        }
      }
    },

    deleteFileX() {
      let updateApp = false
      let updateC = false

      if (this.applyDeleteFileApp) {
        updateApp = new Promise((resolve, reject) => {
          destroyMediaFile(this.previousFileHashApp).then(response => {
            resolve (true)
          }).catch(error => {
            this.responseCatch(error)
            reject (false)
          })
        })
      } else updateApp = true

      if (this.applyDeleteFileC) {
        updateC = new Promise((resolve, reject) => {
          destroyMediaFile(this.previousFileHashC).then(response => {
            resolve(true)
          }).catch(error => {
            this.responseCatch(error)
            reject(false)
          })
        })
      } else updateC = true

      if (updateApp && updateC) {
        this.$emit('change-type-view')
      }
    },

    fileValidation(event, fileIn) {
      if (event.target.file !== 'undefined') {
        const fileX = event.target.files[0]
        // const validSize = this.validatorFileSize(fileX)
        const validType = this.validatorFileType(fileX)

        // if (!validSize) {
        //   this.$refs[fileIn].reset()
        //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
        // }

        if (!validType) {
          this.$refs[fileIn].reset()
          this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        }
      } else this.$refs[fileIn].reset()
    },
  },
}
</script>
